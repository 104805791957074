<template>
  <div>
    <modal
      name="modal-schedule-settings"
      :width="`500px`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="text-center d-flex align-center justify-content-between"
              >
                <span
                  >{{ $t("Does the POS Want a visit?")
                  }}<span class="required ml-2">*</span></span
                >
                <v-radio-group v-model="selected.isScheduled" row>
                  <v-radio v-bind:label="$t('Yes')" :value="true"></v-radio>
                  <v-radio v-bind:label="$t('No')" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="selected.isScheduled === false">
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="selected.reasonType"
                  v-bind:label="$t('reason_type')"
                  :items="reasonTypes"
                  item-text="label"
                  item-value="value"
                  filled
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="selected.isScheduled">
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  ref="scheduleDate_menu"
                  v-model="select_scheduleDate_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.scheduleDate"
                      v-bind:label="$t('Date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.scheduleDate"
                    no-title
                    @input="select_scheduleDate_menu = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :min="nowDate || ''"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="selected.isScheduled">
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  ref="menu"
                  v-model="select_scheduleTime_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="selected.scheduleTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.scheduleTime"
                      v-bind:label="$t('time')"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="select_scheduleTime_menu"
                    v-model="selected.scheduleTime"
                    full-width
                    @click:minute="$refs.menu.save(selected.scheduleTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="selected.isScheduled">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="selected.scheduleContactPerson"
                  v-bind:label="$t('contact_person')"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="selected.comments"
                  v-bind:label="$t('comments')"
                  ref="textarea_gpv_comment"
                  required
                  :rules="requiredRules"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" @click="onSaveClick(selected)">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";

export default {
  name: "DialogScheduleSetting",
  props: ["reasonTypes", "worksessionId", "worksessionPosId", "goNormalVisit"],
  data: function() {
    return {
      select_scheduleDate_menu: false,
      select_scheduleTime_menu: false,
      selected: {},
      requiredRules: [(v) => !!v || "Required"],
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  watch: {},
  computed: {},
  methods: {
    showModal() {
      this.init();
      this.$modal.show("modal-schedule-settings");
    },
    hideModal() {
      this.init();
      this.$modal.hide("modal-schedule-settings");
    },
    async onSaveClick(selected) {
      if (this.$refs.form.validate()) {
        let formdata = {
          ...selected,
        };
        if (formdata.scheduleDate && formdata.scheduleTime) {
          formdata["scheduleDateTime"] = moment(
            new Date(formdata.scheduleDate + " " + formdata.scheduleTime)
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ");
        }
        console.log(selected);
        let { result } = await ApiService.post(
          `worksession/pos/saveschedule/${this.worksessionPosId}`,
          formdata
        );
        if (result) {
          logInfo(this.$t("alert_success"));
          this.hideModal();
          if (selected.isScheduled) {
            this.$router.push({
              name: "router_worksessionroute",
              params: {
                id: this.worksessionId,
              },
            });
          } else {
            this.goNormalVisit();
          }
        }
      }
    },
    init() {
      this.selected = {
        isScheduled: true,
        reasonType: null,
        scheduleDate: null,
        scheduleTime: null,
        scheduleContactPerson: "",
        comments: "",
      };
    },
  },
  async updated() {},
  async mounted() {},
};
</script>

<style>
span.required {
  color: #f00;
  font-weight: bold;
}
</style>
