<template>
  <div style="max-width: 1200px">
    <div class="d-flex flex-column">
      <div class="border d-flex justify-content-between align-center mb-2">
        <router-link :to="`/session/${worksessionId}/route`">
          <b-button class="mr-1" variant="outline-secondary" size="sm">
            <b-icon icon="arrow-left"></b-icon> volver
          </b-button>
        </router-link>

        <template v-if="posSession">
          <span class="trencadis-title"
            >[{{ posSession.po.code || posSession.po.id }}]
            {{ posSession.po.name }}</span
          >
          <sessionTagLabels
            :postaglabels="posSession.po.postaglabels"
          ></sessionTagLabels>
          <div></div>
        </template>
      </div>

      <v-row v-if="posSession">
        <v-col cols="12" sm="12" md="6">
          <div class="pos_detail_wrapper">
            <div class="detail_row">
              <span class="text-bold">POS Details:</span>
            </div>
            <div class="detail_row mt-2">
              Adress: {{ posSession.po.address }}
            </div>
            <div class="detail_row mt-2">Phone Time: 10:00AM</div>

            <template
              v-for="(contactItem, contactKey) in posSession.po.pos_contacts"
            >
              <v-row :key="`contactlistitem_${contactKey}`">
                <v-col cols="12" md="12">
                  <div class="detail_row mt-2">
                    Contact name: {{ contactItem.contact }}
                  </div>
                  <div
                    v-if="contactItem.phone"
                    class="detail_row d-flex align-center justify-content-between mt-2"
                  >
                    Phone 1: +{{ contactItem.phone }}
                    <a class="btn_call_link" :href="`tel:${contactItem.phone}`"
                      >Call</a
                    >
                  </div>
                  <div
                    v-if="contactItem.phone2"
                    class="detail_row d-flex align-center justify-content-between mt-2"
                  >
                    Phone 2: +{{ contactItem.phone2 }}
                    <a class="btn_call_link" :href="`tel:${contactItem.phone2}`"
                      >Call</a
                    >
                  </div>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="d-flex align-center justify-content-center"
        >
          <div class="d-flex flex-column">
            <v-btn color="primary" @click="showAddClientsDialog()"
              >Schedule Visit</v-btn
            >
            <v-btn color="success" class="mt-4" @click="goNormalVisit()"
              >Normal Visit</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <b-alert v-if="!posSession" show variant="info">
        <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
        cargando datos...
      </b-alert>

      <DialogScheduleSetting
        ref="dialogScheduleSettings"
        :reasonTypes="reasonTypes"
        :worksessionId="worksessionId"
        :worksessionPosId="worksessionPosId"
        :goNormalVisit="goNormalVisit"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import DialogScheduleSetting from "./DialogScheduleSetting.vue";
import sessionTagLabels from "@/components/sessionTagLabels";

export default {
  name: "agendapos",
  components: {
    DialogScheduleSetting,
    sessionTagLabels,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  data: function() {
    return {
      worksessionId: null,
      worksessionPosId: null,
      posSession: null,
      reasonTypes: [],
    };
  },
  methods: {
    moment,

    showAddClientsDialog() {
      this.$refs.dialogScheduleSettings.showModal();
    },
    goNormalVisit() {
      this.$router.push({
        name: "router_worksessionpos",
        params: {
          id: this.worksessionId,
          worksessionPosId: this.worksessionPosId,
        },
      });
    },
    async init() {
      this.worksessionId = this.$route.params.id;
      this.worksessionPosId = this.$route.params.worksessionPosId;
      let { data, reasonTypes } = await ApiService.get(
        `worksession/pos/${this.worksessionPosId}`
      );
      this.posSession = data;
      console.log(reasonTypes);
      if (reasonTypes) {
        this.reasonTypes = [];
        for (const [value, label] of Object.entries(reasonTypes)) {
          let push_item = {
            value,
            label,
          };
          this.reasonTypes.push(push_item);
        }
      }
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>

<style>
.pos_detail_wrapper {
  border: 1px solid #999;
  padding: 10px;
}
.pos_detail_wrapper .text-bold {
  font-weight: bold;
}
a.btn_call_link {
  height: 36px;
  padding: 0 16px;
  background-color: #1e88e5 !important;
  border-color: #1e88e5 !important;
  font-size: 0.875rem;
  align-items: center;
  color: inherit;
  display: flex;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-indent: 0.0892857143em em;
  color: #fff;
}
</style>
